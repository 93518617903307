import "./style.scss";

import Badge from "components/Secondary/Badge";
import HeroScreen from "assets/images/engine.png";
import Button from "components/Secondary/Button";

const Hero = () => {
  return <div className="_homeInner" id="home">

    <div className="right">
      <img src={HeroScreen} className="heroImage"/>
    </div>

    <div className="left">
      
      <Badge href={"#"} highlight={"Symmetry Engine"} text={"SDK Documentation"}/>
      <p className="Display-xl-sb heroText">
        Supercharge your DeFi app
      </p>

      <p className="Text-xl-n sub">
        Symmetry Engine lets you create on-chain funds and concentrated liquidity pools with ease, and it's <b>free.</b>
      </p>

      <div className="buttons">
        <Button target="_self" link="true" href="#features" className="join" type="secondary" text={"Check out Features"}/>
        <Button target="_blank" link="true" href="https://docs.symmetry.fi" className="cta" type="primary" text={"Go to Docs"}/>
      </div>
      
    </div>
  </div>
}

export default Hero;