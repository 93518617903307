import "./style.scss";
import Screen from "assets/images/symmetryscreen.png";

const Testimonials = () => {


  return <div className="testimonials">
    <div className="testimonialsInner">
      <div className="left">
        <p className="Display-md-m text">Building on Solana?</p>
        <p className="Text-xl-n subtext">Whether you're building an asset management platform or a DeFi app that can take advantage of Symmetry's on-chain Engine, we can support your project grow!</p>
        <a className="customButton _button_base" href="/developers">Explore</a>
      </div>
      <div className="right">
        <img src={Screen} className="image"/>
      </div>
    </div>
  </div>
}
export default Testimonials;