import "./style.scss";
import TwitterIcon from "assets/icons/twitter.svg";
import Github from "assets/icons/github.svg";
import Discord from "assets/icons/discord.svg";
import Telegram from "assets/icons/telegram.svg";
import {Link} from "react-router-dom";

import Divider from "assets/icons/divider.svg";
import Logo from "components/Secondary/Logo";


const Footer =() => {
  return <div className="footer">

    <div className="footerTop">
      <div className="inner">
        <div className="left">
          <p className="Display-sm-sb">
            Join the community
          </p>
          <p className="Text-xl-n sub">
            Stay up to date with the latest news, announcements, and articles.
          </p>
        </div>
        <div className="right">
          <a href="https://twitter.com/symmetry_fi" target={"_blank"}>
            <img src={TwitterIcon}/>
          </a>
          <a href="http://discord.gg/ahdqBRgE7G" target={"_blank"}>
            <img src={Discord}/>
          </a>
          <a href="https://t.me/symmetry_fi" target={"_blank"}>
            <img src={Telegram}/>
          </a>
          <a href="https://github.com/symmetry-protocol" target={"_blank"}>
            <img src={Github}/>
          </a>
        </div>
      </div>
    </div>
    <div className="footerBottom">
      <div className="inner">
        <div className="top">
          <div className="left">
            <Logo height={32}/>
            <p className="Text-md-n sub">
              Asset Management made simple.
            </p>
          </div>
          <div className="right">

            <div className="section">
              <p className="Text-sm-sb title">
                Product
              </p>
              <div className="sub">
                <a href={"#home"} className="Text-md-m">
                  Home
                </a>
                <a href={"#features"} className="Text-md-m">
                  Features
                </a>
                <a href={"#metrics"} className="Text-md-m">
                  Metrics
                </a>
                {/* <a href={"#grants"} className="Text-md-m">
                  Grants
                </a> */}
              </div>
            </div>

            <div className="section">
              <p className="Text-sm-sb title">
                Company
              </p>
              <div className="sub">
                <a href={"https://symmetry-fi.medium.com/introducing-symmetry-a-decentralized-crypto-index-protocol-2c757413842e"} target={"_blank"} className="Text-md-m">
                  About
                </a>
                <a href={"https://jobs.solana.com/companies/symmetry?q=symmetry#content"} target={"_blank"} className="Text-md-m">
                  Careers
                </a>
                <a href={"https://docs.symmetry.fi/media-kit"} target={"_blank"} className="Text-md-m">
                  Media Kit
                </a>
                <a href={"mailto:operations@symmetry.fi"} className="Text-md-m">
                  Contact
                </a>
              </div>
            </div>

            <div className="section">
              <p className="Text-sm-sb title">
                Resources
              </p>
              <div className="sub">
                <a href={"https://github.com/symmetry-protocol"} target={"_blank"} className="Text-md-m">
                  Github
                </a>
                <a href={"https://docs.symmetry.fi"} target={"_blank"} className="Text-md-m">
                  Documentation
                </a>
              </div>
            </div>

            <div className="section">
              <p className="Text-sm-sb title">
                Social
              </p>
              <div className="sub">
                <a href={"https://twitter.com/symmetry_fi/"} target={"_blank"} className="Text-md-m">
                  Twitter
                </a>
                <a href={"http://discord.gg/ahdqBRgE7G"} target={"_blank"} className="Text-md-m">
                  Discord
                </a>
                <a href={"https://t.me/symmetry_fi"} target={"_blank"} className="Text-md-m">
                  Telegram
                </a>
                <a href={"https://symmetry-fi.medium.com/"} target={"_blank"} className="Text-md-m">
                  Medium
                </a>
              </div>
            </div>

          </div>
        </div>
        <div className="bottom">
          <img src={Divider}/>
          <div className="flex justify-between">
            <p className="copyright Text-md-n">
              © 2022 Symmetry. All rights reserved.
            </p>
          </div>
        </div>
      </div>
      
    </div>
  </div>
}
export default Footer;