import { useEffect, useState } from "react";

export const CryptoIcon = ({ name = "", width = "27", logo = undefined }) => {
  let logoURI = "";
  if (name !== undefined && name.length > 0) {
    if (logo)
      logoURI = logo;
    if (logoURI === "" || logoURI === undefined) {
      return <span style={{ fontSize: "20px" }}>&#129300;</span>;
    }
    return (
      <img
        src={logoURI}
        alt={name}
        height={width}
        width={width}
        style={{ borderRadius: "50%" }}
      />
    );
  } else {
    return <span style={{ fontSize: "20px" }}>&#129300;</span>;
  }
};

export function findLogoFromSymbol(tokenList, symbol) {
  for (let i=0;i<tokenList.length;i++)
    if (tokenList[i].symbol == symbol)
      return tokenList[i].logoURI;
  return "";
}

export const formatUSD = (amount, toFixed=4) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: toFixed,
  });

  return formatter.format(amount);

  
}



export const ConfettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: "50",
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3500,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "867px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

export const convertHistoricalData = (data, asset) => {
  if (!data)
    return null;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    for (let token in data[i].tokens) {
      if (token == asset.toUpperCase() || token == asset) {
        arr.push({
          date: data[i].time * 1000,
          price: data[i].tokens[token],
        });
      }
    }
  }
  if (arr.length == 0)
    return null
  return arr;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}