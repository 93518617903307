import Features from "components/Developers/Sections/Features";
import Grants from "components/Developers/Sections/Grants";
import Hero from "components/Developers/Sections/Hero";
import Footer from "components/Sections/Footer";
import Metrics from "components/Sections/Metrics";
import SocialProof from "components/Sections/SocialProof";
import "./style.scss";


const Developers = ({}) => {

  return <div className="homePage">
    <Hero/>
    <SocialProof/>
    <Features/>
    <Metrics/>
    {/* <Grants/> */}
    <Footer/>
  </div>
}

export default Developers;