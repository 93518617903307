import "./style.scss";
import {ReactComponent as ArrowRight} from "assets/icons/arrowRight.svg";

const Badge = ({href, highlight, text }) => {
  return <a href={href} target={"_blank"} className="badge">
    <p className="Text-sm-m highlight">
      {highlight}
    </p>
    <p className="Text-sm-m text">
      {text}
    </p>
    <ArrowRight className="arrow"/>
  </a>
}

export default Badge;