import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import "./App.scss";
import Home from "pages/Home";
import Header from "components/Primary/Header";
import Developers from "pages/Home/developers";


const App = () => {
  const [page, setPage] = useState(0);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Header/>
            <Home/>
          </Route>
          <Route exact path="/developers">
            <Header/>
            <Developers/>
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
