import "./style.scss";
import BasketsIcon from "assets/icons/baskets.svg";
import IntegrationsIcon from "assets/icons/integrations.svg";
import LightningIcon from "assets/icons/lightning.svg";

import Button from "components/Secondary/Button";
import IndexImage from "assets/images/index.png";
import BasketsImage from "assets/images/baskets.png";
import AMFImage from "assets/images/amf.png";
import RetailImage from "assets/images/retail.png";


const Features = () => {
  return <div className="features" id="features">
    <div className="featuresInner">
      <div className="top">

        <p className="Text-md-sb title">Use-cases</p>
        <p className="Display-md-sb headline">Symmetry Engine</p>
        <p className="Text-xl-n sub">
          Developers can use Symmetry Engine SDK to deploy index funds & actively managed funds on-chain and automate them.
        </p>
      </div>

      <div className="feature">
        <div className="left">
          <img className="icon" src={BasketsIcon}/>
          <p className="Display-sm-sb name">Index Funds</p>
          <p className="Text-lg-n description">
            Permissionless/Permissioned Index Fund creation, flexible asset composition filtering settings, on-chain rebalancing & reweighing.
          </p>
          <Button link={true} href="https://docs.symmetry.fi/symmetry-engine/sdks/funds-sdk" target={"_blank"} className="cta" type="primary" text={"Go to Docs"}/>
        </div>
        <div className="right">
          <img src={IndexImage} className="image"/>
        </div>
      </div>

      <div className="feature reversed">
        <div className="left">
          <img className="icon" src={BasketsIcon}/>
          <p className="Display-sm-sb name">Actively Managed Funds</p>
          <p className="Text-lg-n description">
            Creation, Management & Automation of Actively Managed Funds through Funds SDK.
          </p>
          <Button link={true} href="https://docs.symmetry.fi/symmetry-engine/sdks/funds-sdk" target={"_blank"} className="cta" type="primary" text={"Go to Docs"}/>
        </div>
        <div className="right">
          <img src={AMFImage} className="image"/>
        </div>
      </div>

      <div className="feature">
        <div className="left">
          <img className="icon" src={IntegrationsIcon}/>
          <p className="Display-sm-sb name">Market Making</p>
          <p className="Text-lg-n description">
            Symmetry Funds can act as market makers, providing concentrated liquidity to DEXes and earning fees for the fund holders.
          </p>
          <Button link={true} href="https://docs.symmetry.fi/symmetry-engine/sdks/liquidity-sdk" target={"_blank"} className="cta" type="primary" text={"Go to Docs"}/>
        </div>
        <div className="right">
          <img src={BasketsImage} className="image"/>
        </div>
      </div>
      
      <div className="feature reversed">
        <div className="left">
          <img className="icon" src={LightningIcon}/>
          <p className="Display-sm-sb name">Retail Onboarding</p>
          <p className="Text-lg-n description">
            Solana offers unprecedented transaction speeds and virtually non-existent fees.
            This unlocks the potential for developers to onboard millions of users into crypto using apps built on Symmetry.
          </p>

          <Button link={true} href="https://docs.symmetry.fi/" target={"_blank"} className="cta" type="primary" text={"Go to Docs"}/>
          
        </div>
        <div className="right">
          <img src={RetailImage} className="image"/>
        </div>
      </div>

      {/* <div className="feature">
        <div className="left">
          <img src={EasyToUseIcon}/>
          <p className="Display-sm-sb name">Most of all — Easy to use</p>
          <p className="Text-lg-n description">
            Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop. Leverage automation to move fast, while always giving customers a human, helpful experience.
          </p>
          <Button className="cta" type="primary" text={"Learn more"}/>
        </div>
        <div className="right">
          <img src={Easy} className="image"/>
        </div>
      </div> */}

    </div>
  </div>
}

export default Features;