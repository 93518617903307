import { Dialog } from "@mui/material";
import "./style.scss";



const VideoPopup = ({open, onClose}) => {
  return <Dialog
    classes={{ root: "popup_container", paper: "videoPopup" }}
    aria-labelledby="simple-dialog-title"
    open={open}
    onClose={onClose}
    transitionDuration={0.2}
  >
    <iframe width="100%" height="480" src="https://www.youtube.com/embed/qwJbyPuuPBI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Dialog>
}

export default VideoPopup;