import "./style.scss";

const Button = ({type="base"||"primary",text, icon=null,className="", style={}, link=false, href="", target="_blank"}) => {
  
  if(link) {
    return <a href={href} target={target} style={style} className={"customButton _button_" + type + " " + className}>
      {
        icon &&
        icon
      }
      {text}
    </a>
  }
  if(!link)
  return <div style={style} className={"customButton _button_" + type + " " + className}>
    {
      icon &&
      icon
    }
    {text}
  </div>
}

export default Button