import "./style.scss";

import SerumIcon from "assets/images/serum.png";
import PrismIcon from "assets/images/prism.svg";
import SolaniumIcon from "assets/images/solanium.svg";
import MarinadeIcon from "assets/images/marinade.svg";
import OrcaIcon from "assets/images/orca.svg";
import StarLaunchIcon from "assets/images/starlaunch.svg";
import PsyOptionsIcon from "assets/images/psyoptions.png";
import SoceanIcon from "assets/images/socean.png";
import ZetaIcon from "assets/images/zeta.png";
import CompanionIcon from "assets/images/companion.png";


const SocialProof = () => {

  return <div className="socialProof">
    <div className="socialProofInner">
      <p className="Text-md-m title">Partners</p>

      <div className="partners">

        <a target={"_blank"} href="https://prism.ag" className="partner">
          <img src={PrismIcon} height={30}/>
          <p className="Text-md-sb name">Prism</p>
        </a>

        <a target={"_blank"} href="https://projectserum.com" className="partner">
          <img src={SerumIcon} height={30}/>
          <p className="Text-md-sb name">Serum</p>
        </a>

        <a target={"_blank"} href="https://solanium.io" className="partner">
          <img src={SolaniumIcon} height={30}/>
          <p className="Text-md-sb name">Solanium</p>
        </a>

        <a target={"_blank"} href="https://psyoptions.io" className="partner">
          <img src={PsyOptionsIcon} height={30}/>
          <p className="Text-md-sb name">PsyOptions</p>
        </a>

        <a target={"_blank"} href="https://companion.to" className="partner">
          <img src={CompanionIcon} height={30}/>
          <p className="Text-md-sb name">Companion.to</p>
        </a>
        <a target={"_blank"} href="https://marinade.finance/" className="partner">
          <img src={MarinadeIcon} height={30}/>
          <p className="Text-md-sb name">Marinade</p>
        </a>
        <a target={"_blank"} href="https://zeta.markets" className="partner">
          <img src={ZetaIcon} height={30}/>
          <p className="Text-md-sb name">Zeta</p>
        </a>

        

        <a target={"_blank"} href="https://socean.fi" className="partner">
          <img src={SoceanIcon} height={30}/>
          <p className="Text-md-sb name">Socean</p>
        </a>

        <a target={"_blank"} href="https://orca.so" className="partner">
          <img src={OrcaIcon} height={30}/>
          <p className="Text-md-sb name">Orca</p>
        </a>
        
        <a target={"_blank"} href="https://starlaunch.com" className="partner">
          <img src={StarLaunchIcon} height={30}/>
          <p className="Text-md-sb name">StarLaunch</p>
        </a>
      </div>
    </div>
  </div>
}

export default SocialProof;