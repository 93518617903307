import "./style.scss";

import Badge from "components/Secondary/Badge";
import HeroScreen from "assets/images/heroImage.png";
import Button from "components/Secondary/Button";
import SymmetryImage from "assets/images/symmetry.png";
import { useState } from "react";
import VideoPopup from "components/Secondary/VideoPopup";

const Hero = () => {
  const [videoOpened, setVideoOpened] = useState(false);

  return <div className="homeInner" id="home">
    <div className="left">
      
      <Badge href={"https://app.symmetry.fi"} highlight={"Symmetry Platform"} text={"is now Live!"}/>
      <p className="Display-xl-sb heroText">
        Asset Management Simplified
      </p>

      <p className="Text-xl-n sub">
        Infrastructure for creating, automating, managing and tracking on-chain funds.
      </p>

      <div className="buttons">
        <Button link={true} href={"/#features"} target="_self" className="join" type="secondary" text={"Check out Features"}/>
        <Button link={true} href={"https://app.symmetry.fi"} target="_blank" className="cta" type="primary" text={"Get Started"}/>
      </div>
      
    </div>
    <div className="right">
    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/qwJbyPuuPBI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      <img className="heroImage" onClick={() => setVideoOpened(true)}/>
    </div>
    <VideoPopup open={videoOpened} onClose={() => setVideoOpened(false)}/>
  </div>
}

export default Hero;