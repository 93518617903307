import Button from "components/Secondary/Button";
import Logo from "components/Secondary/Logo";
import "./style.scss";
import {Link} from "react-router-dom";
import {useWindowDimensions} from "helpers/helpers";
import {ReactComponent as MenuIcon} from "assets/icons/menu.svg";
import { useState } from "react";
import {ReactComponent as CloseIcon} from "assets/icons/close.svg";

const Header = ({}) => {

  const {width} = useWindowDimensions();
  const [burgerMenuOpened, setBurgerMenuOpened] = useState(false);

  return <>
    <div className="header">

      {
        width > 768 &&
        <div className="headerInner">
          <div className="left">
            <Logo height={32}/>

            <div className="menu">
              <Link className="Text-md-m" to={"/"}>Home</Link>
              <a className="Text-md-m" href="#features">Products</a>
              <Link className="Text-md-m" to={"/developers"}>Developers</Link>
              <a className="Text-md-m" target='_blank' href="https://twitter.com/symmetry_fi">Socials</a>
            </div>
          </div>

          <div className="right">
            <Button type="primary" link={true} href={"https://app.symmetry.fi"} target="_blank" text={"Get Started"}/>
          </div>
        </div>
      }
      
      {
        width <= 768 &&
        <div className="headerInner">
          <div className="left">
            <Logo height={32}/>
          </div>

          <div className="right">
            { burgerMenuOpened ?
              <CloseIcon onClick={() => setBurgerMenuOpened(() => !burgerMenuOpened)} className="menuBurger" stroke={"var(--gray700)"}/>
              :
              <MenuIcon onClick={() => setBurgerMenuOpened(() => !burgerMenuOpened)} className="menuBurger" stroke={"var(--gray700)"}/>
            }
          </div>
        </div>
      }
    </div>
    { burgerMenuOpened &&
      <div className="burgerMenu">
        <div className="header">
          <div className="headerInner">
            <div className="left">
              <Logo height={32}/>
            </div>

            <div className="right">
              <CloseIcon onClick={() => setBurgerMenuOpened(() => !burgerMenuOpened)} className="menuBurger" stroke={"var(--gray700)"}/>
            </div>
          </div>
        </div>
        
        <div className="inner">
        <Link to={"/"} onClick={() => setBurgerMenuOpened(false)} className="menuItem">
            Home
          </Link>
          <a href={"/#features"} onClick={() => setBurgerMenuOpened(false)} className="menuItem">
            Products
          </a>
          <a href={"/developers"} onClick={() => setBurgerMenuOpened(false)} className="menuItem">
            Developers
          </a>

          <Button type="primary" text={"Get Started"}/>
        </div>
      </div>
    }
  </>
}

export default Header;