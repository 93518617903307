import "./style.scss";
import BasketsIcon from "assets/icons/baskets.svg";
import AnalyticsIcon from "assets/icons/analytics.svg";
import IntegrationsIcon from "assets/icons/integrations.svg";
import LightningIcon from "assets/icons/lightning.svg";
import EasyToUseIcon from "assets/icons/easytouse.svg";

import Button from "components/Secondary/Button";

import IndexImage from "assets/images/index.png";
import DeeplyIntegrated from "assets/images/integrated.png";
import PortfolioManagement from "assets/images/portfolio.png";
import Fast from "assets/images/fast.png";
import Easy from "assets/images/easy.png";



const Features = () => {
  return <div className="features" id="features">
    <div className="featuresInner">
      <div className="top">

        <p className="Text-md-sb title">Features</p>
        <p className="Display-md-sb headline">Your gateway to Solana DeFi</p>
        <p className="Text-xl-n sub">
          The only DeFi platform you need. Send and receive, stake and farm yield, borrow and lend, invest in tried-and-tested portfolio strategies.
        </p>
      </div>

      <div className="feature">
        <div className="left">
          <img className="icon" src={BasketsIcon}/>
          <p className="Display-sm-sb name">On-chain Funds</p>
          <p className="Text-lg-n description">
            Ready-made portfolio strategies and index funds represented by single tokens that passively earn and rebalance. Symmetry Indexes create a rebalancing pool that acts as a liquidity provider to DEXes, earning fees for the index holders instead of paying them.
          </p>
          <Button link={true} href="https://app.symmetry.fi/" target={"_self"} className="cta" type="primary" text={"Explore Funds"}/>
        </div>
        <div className="right">
          <img src={IndexImage} className="image"/>
        </div>
      </div>

      <div className="feature reversed">
        <div className="left">
          <img className="icon" src={AnalyticsIcon}/>
          <p className="Display-sm-sb name">Portfolio Management</p>
          <p className="Text-lg-n description">
            Symmetry portfolio manager gives you all the tools you need to manage crypto portfolio with ease. Whether it's tracking performance, Swapping assets, staking and farming yield, lending or borrowing and more.
          </p>
          <Button link={true} href="https://portfolio.symmetry.fi/" target={"_self"} className="cta" type="primary" text={"Manage Portfolio"}/>
        </div>
        <div className="right">
          <img src={PortfolioManagement} className="image"/>
        </div>
      </div>

      <div className="feature">
        <div className="left">
          <img className="icon" src={IntegrationsIcon}/>
          <p className="Display-sm-sb name">Deeply Integrated</p>
          <p className="Text-lg-n description">
            Integrated with all major protocols in the ecosystem, Symmetry offers all-in-one functionality when it comes to all things DeFi.
          </p>
          {/* <Button className="cta" type="primary" text={"Learn more"}/> */}
        </div>
        <div className="right">
          <img src={DeeplyIntegrated} className="image"/>
        </div>
      </div>
      
      <div className="feature reversed">
        <div className="left">
          <img className="icon" src={LightningIcon}/>
          <p className="Display-sm-sb name">Lightning Fast & Cheap</p>
          <p className="Text-lg-n description">
            Solana offers unprecedented transaction speeds and virtually non-existent fees.
            This means, using Symmetry is like using any other web application — fast & efficient.
          </p>

          <Button link={true} href="https://solana.com/" target={"_blank"} className="cta" type="primary" text={"Learn more"}/>
          
        </div>
        <div className="right">
          <img src={Fast} className="image"/>
        </div>
      </div>

      {/* <div className="feature">
        <div className="left">
          <img src={EasyToUseIcon}/>
          <p className="Display-sm-sb name">Most of all — Easy to use</p>
          <p className="Text-lg-n description">
            Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop. Leverage automation to move fast, while always giving customers a human, helpful experience.
          </p>
          <Button className="cta" type="primary" text={"Learn more"}/>
        </div>
        <div className="right">
          <img src={Easy} className="image"/>
        </div>
      </div> */}

    </div>
  </div>
}

export default Features;