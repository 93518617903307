import "./style.scss";

import Hero from "components/Sections/Hero";
import SocialProof from "components/Sections/SocialProof";
import Features from "components/Sections/Features";
import Testimonials from "components/Sections/Testimonials";
import Metrics from "components/Sections/Metrics";
import Grants from "components/Sections/Grants";
import Footer from "components/Sections/Footer";

const Home = ({}) => {

  return <div className="homePage">
    <Hero/>
    <SocialProof/>
    <Features/>
    <Metrics/>
    <Testimonials/>
    <Footer/>
  </div>
}

export default Home;