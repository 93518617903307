import "./style.scss";
import TrustedIcon from "assets/icons/trusted.svg";

const Metrics = () => {
  return <div className="metrics" id="metrics">
    <div className="metricsInner">
      <div className="top">
        <img src={TrustedIcon}/>
        <p className="Display-md-sb title">Tried and Tested</p>
        <p className="Text-xl-n description">Thousands of users manage their assets using Symmetry every day.</p>
      </div>
      <div className="stats">
        <div className="stat">
          <p className="Display-xl-sb top">
            420k+
          </p>
          <p className="Text-lg-m bottom">
            Transactions
          </p>
        </div>
        <div className="stat">
          <p className="Display-xl-sb top">
            $500m+
          </p>
          <p className="Text-lg-m bottom">
            Volume
          </p>
        </div>
        <div className="stat">
          <p className="Display-xl-sb top">
            100k+
          </p>
          <p className="Text-lg-m bottom">
            Users
          </p>
        </div>
      </div>
    </div>
  </div>
}

export default Metrics;