import {ReactComponent as LogoDark} from "assets/logo/logoDark.svg";
import {ReactComponent as LogoLight} from "assets/logo/logoLight.svg";
import {ReactComponent as LogoIcon} from "assets/logo/logo.svg";
import "./style.scss";


const Logo = ({height=30}) => {

  return <a href="https://symmetry.fi" className="logoComponent">
    <LogoIcon height={height}/>
    <p>Symmetry</p>
  </a>
  
}

export default Logo;